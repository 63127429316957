body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-bg-color: #000000; /* 기본 배경색 */
  --secondary-bg-color: #e6e6e6; /* 기본 배경색 */
  --primary-text-color: #ffffff; /* 기본 텍스트 색상 */
  --hover-bg-color: #000000; /* 호버 시 배경색 */
  --hover-text-color: #00aa34; /* 호버 시 글씨 색상 */
  --navlink-font-size: 1.5rem; /* NavLink 기본 폰트 크기 */
  --navlink-hover-font-size: 1.6rem; /* NavLink 호버 시 폰트 크기 */
}
